import HttpStatus from "http-status";
import { put, select } from "redux-saga/effects";

import { authorizationActions, authorizationSelectors } from "@aft/client-modules/authorization";

/**
 * API error handler.
 *
 * @param error - Error instance.
 */
export function* handleApiError(error) {
  if (error.message === HttpStatus[HttpStatus.UNAUTHORIZED]) {
    const isSignedIn = yield select(authorizationSelectors.selectIsSignedIn);

    if (isSignedIn) {
      yield put(authorizationActions.signOutRequest());
    }

    return;
  }
}
