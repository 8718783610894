import {
  AsyncActionStateDefaultValues,
  getAsyncActionStage,
  actionTypeToMethodName,
} from "@aft/client-modules/helpers";

/**
 * Creates selector to select action state that is corresponding to a provided action type from the store.
 *
 * @param actionType - Action type, that is corresponding to a particular async action.
 */
export const createAsyncActionStateSelector = (actionType) => ({ application }) => {
  const asyncActionStage = getAsyncActionStage(actionType);

  if (asyncActionStage === undefined) {
    throw new Error(`Action type '${actionType}' does not correspond to an async action.`);
  }

  return {
    ...AsyncActionStateDefaultValues,
    ...(application.asyncActionStates[actionTypeToMethodName(actionType)] || {}),
  };
};

/**
 * Indicates whether any async action is in the loading state currently.
 *
 * @param store - Combined store.
 * @param store.application - Authorization store.
 */
export const selectIsAnyAsyncActionLoading = ({ application }) =>
  Object.values(application.asyncActionStates).some(({ loading }) => loading);
