import {
  SET_SIGN_IN_REASON,
  SIGN_IN_SUCCESS,
  SESSION_CONTINUE_SUCCESS,
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS,
  SIGN_OUT_SUCCESS,
} from "./actionTypes";

export const authorizationReducerInitialState = {
  isSignedIn: false,
  signInReason: undefined,

  signUpData: undefined,
};

/**
 * Reducer, responsible for handling actions and store state relative to authorization.
 *
 * @param state - Current state of the reducer.
 * @param action - Action to be applied.
 */
export const authorizationReducer = (state = authorizationReducerInitialState, action) => {
  switch (action.type) {
    case SET_SIGN_IN_REASON:
      return {
        ...state,

        signInReason: action.signInReason,
      };
    case SIGN_UP_REQUEST:
      return {
        ...state,

        signUpData: undefined,
      };
    case SIGN_OUT_SUCCESS:
      return {
        ...state,

        isSignedIn: false,
      };
    case SIGN_IN_SUCCESS:
    case SESSION_CONTINUE_SUCCESS:
      return {
        ...state,

        isSignedIn: true,
      };
    case SIGN_UP_SUCCESS:
      return {
        ...state,

        signUpData: action.signUpData,
      };
    default:
      return state;
  }
};
