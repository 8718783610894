import {
  AsyncActionStages,
  AsyncActionStateDefaultValues,
  actionTypeToMethodName,
  getAsyncActionStage,
} from "@aft/client-modules/helpers";

import { ROUTE_CHANGE_COMPLETE, RESET_ASYNC_ACTION_STATE } from "./actionTypes";

export const applicationReducerInitialState = {
  asyncActionStates: {},
};

/**
 * Reducer, responsible for handling actions and store state relative to whole application and its actions.
 *
 * @param state - Current state of the reducer.
 * @param action - Action to be applied.
 */
export const applicationReducer = (state = applicationReducerInitialState, action) => {
  switch (action.type) {
    case ROUTE_CHANGE_COMPLETE:
      return {
        ...state,

        // Reset async actions state on hydration, as they are part of a certain page.
        asyncActionStates: {},
      };
    case RESET_ASYNC_ACTION_STATE:
      return {
        ...state,

        asyncActionStates: {
          ...state.asyncActionStates,

          [actionTypeToMethodName(action.asyncActionType)]: { ...AsyncActionStateDefaultValues },
        },
      };
    default:
      break;
  }

  // Validate action types, coming from other modules.
  // If this is async action, then save it's progress to a store.
  const asyncActionStage = getAsyncActionStage(action.type);
  if (asyncActionStage !== undefined) {
    const methodName = actionTypeToMethodName(action.type);

    switch (asyncActionStage) {
      case AsyncActionStages.Request:
        return {
          ...state,

          asyncActionStates: {
            ...state.asyncActionStates,

            [methodName]: {
              ...AsyncActionStateDefaultValues,

              loading: true,
              success: false,
              isRetry: action.isRetry || false,
            },
          },
        };
      case AsyncActionStages.Success:
        return {
          ...state,

          asyncActionStates: {
            ...state.asyncActionStates,

            [methodName]: {
              ...AsyncActionStateDefaultValues,

              success: true,
              isRetry: (state.asyncActionStates[methodName] || {}).isRetry,
            },
          },
        };
      case AsyncActionStages.Failure:
        return {
          ...state,

          asyncActionStates: {
            ...state.asyncActionStates,

            [methodName]: {
              ...AsyncActionStateDefaultValues,

              error: action.error,
              isRetry: (state.asyncActionStates[methodName] || {}).isRetry,
            },
          },
        };
      default:
        return state;
    }
  }

  return state;
};
