import crypto from "crypto";

import { settings } from "@aft/client-settings";

const ENCRYPTION_ALGORITHM = "aes-256-ctr";

/**
 * Encryption function.
 *
 * @param text - Text to be encrypted.
 */
export const encrypt = (text) => {
  const initVector = crypto.randomBytes(16);
  const cipher = crypto.createCipheriv(ENCRYPTION_ALGORITHM, settings.Crypto.SecretKey, initVector);

  const encrypted = Buffer.concat([cipher.update(text), cipher.final()]);

  return {
    initVector: initVector.toString("hex"),
    content: encrypted.toString("hex"),
  };
};

/**
 * Decryption function.
 *
 * @param encrypted - Encrypted value.
 * @param encrypted.initVector - Init vector of the encrypted value.
 * @param encrypted.content - Encrypted value content.
 */
export const decrypt = ({ initVector, content }) => {
  const decipher = crypto.createDecipheriv(
    ENCRYPTION_ALGORITHM,
    settings.Crypto.SecretKey,
    Buffer.from(initVector, "hex"),
  );

  const decrpyted = Buffer.concat([decipher.update(Buffer.from(content, "hex")), decipher.final()]);

  return decrpyted.toString();
};
