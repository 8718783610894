import { camelCase } from "change-case";

import { FormFieldNames, COMMENT_FIELD_NAME_PART, OTHER_OPTION } from "./constants";

/**
 * Validates whether the provided field name is corresponding to a password field.
 *
 * @param name - Form field name.
 */
export const isPasswordFieldName = (name) =>
  [
    FormFieldNames.Password,
    FormFieldNames.ConfirmPassword,
    FormFieldNames.NewPassword,
    FormFieldNames.ConfirmNewPassword,
    FormFieldNames.CurrentPassword,
  ].includes(name);

/**
 * Returns label for a provided field name.
 *
 * @param t - Translation function.
 * @param label - Form field label or name. If relative translation key is absent, raw value will be used.
 * @param required - Indicates whether field is required, optional, or without such marker.
 */
export const getFormFieldLabel = (t, label, required) => {
  const translatedLabel = t(`Form.FieldName.${label}`, label);

  if (required !== undefined) {
    return t(`Form.Label.${required ? "RequiredField" : "OptionalField"}`, {
      label: translatedLabel,
    });
  }

  return translatedLabel;
};

/**
 * Transform text strings to field name.
 *
 * @param args - Text strings to transform.
 */
export const textToFieldName = (...args) => camelCase(args.join(" "));

/**
 * Transform text to a field name, corresponding to a comment field.
 *
 * @param args - Text strings to transform.
 */
export const textToCommentFieldName = (...args) =>
  textToFieldName(...args, COMMENT_FIELD_NAME_PART);

/**
 * Transform text to a field name, corresponding to a 'other' (free comment) option in the survey.
 *
 * @param args - Text strings to transform.
 */
export const textToOtherOptionFieldName = (...args) => textToFieldName(...args, OTHER_OPTION);

/**
 * Transform form values to survey results based on questions list.
 *
 * @param questionsList - List of questions.
 * @param values - Form values.
 */
export const transformSurveyValuesToResults = (questionsList, values) =>
  questionsList.map(({ Question, Options }) => {
    const fieldName = textToFieldName(Question);

    return {
      question: Question,
      selectedOption: Options.find(
        (option) => textToFieldName(Question, option) === values[fieldName],
      ),
      comment: values[textToCommentFieldName(Question)],
    };
  });

/**
 * Transform form values to results for the questions, which behave as ones with the open answers.
 *
 * @param questionsList - List of questions.
 * @param values - Form values.
 */
export const transformOpenAnswerQuestionsToResults = (questionsList, values) =>
  questionsList.map(({ Question }) => ({
    question: Question,
    selectedOption: undefined,
    comment: values[textToFieldName(Question)],
  }));
