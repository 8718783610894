import {
  SET_PHONE_NUMBER,
  PHONE_VALIDATION_REQUEST,
  PHONE_VALIDATION_SUCCESS,
  REQUEST_PHONE_VERIFICATION_REQUEST,
  REQUEST_PHONE_VERIFICATION_SUCCESS,
} from "./actionTypes";

export const phoneReducerInitialState = {
  phoneNumber: undefined,
  validated: undefined,
  phoneVerificationToken: undefined,
};

/**
 * Reducer, responsible for handling actions and store state relative to phone data.
 *
 * @param state - Current state of the reducer.
 * @param action - Action to be applied.
 */
export const phoneReducer = (state = phoneReducerInitialState, action) => {
  switch (action.type) {
    case SET_PHONE_NUMBER:
      return {
        ...state,

        phoneNumber: action.phoneNumber,
        phoneVerificationToken: undefined,
        validated: undefined,
      };
    case PHONE_VALIDATION_REQUEST:
      return {
        ...state,

        validated: undefined,
      };
    case REQUEST_PHONE_VERIFICATION_REQUEST:
      return {
        ...state,

        phoneVerificationToken: undefined,
        validated: undefined,
      };
    case PHONE_VALIDATION_SUCCESS:
      return {
        ...state,

        validated: true,
      };
    case REQUEST_PHONE_VERIFICATION_SUCCESS:
      return {
        ...state,

        phoneVerificationToken: action.phoneVerificationToken,
        validated: true,
      };
    default:
      return state;
  }
};
