import { omit } from "ramda";

import { settings } from "@aft/client-settings";

/**
 * Recursively transforms route object config to route object.
 *
 * @param routeObjectConfig - Route object config.
 * @param pathname - Route pathname.
 */
const transformConfigToRouteObject = (routeObjectConfig, pathname) => {
  const routeObject = {
    ...omit(["nested"], routeObjectConfig),
    pathname: pathname || "/",
    nested: {},
  };

  if (routeObjectConfig.nested !== undefined) {
    Object.keys(routeObjectConfig.nested).forEach((key) => {
      if (routeObjectConfig.nested !== undefined) {
        routeObject.nested[key] = transformConfigToRouteObject(
          routeObjectConfig.nested[key],
          `${pathname || ""}/${key}`,
        );
      }
    });
  }

  return routeObject;
};

/**
 * Class for client service routes management.
 */
export class ClientRoutesManager {
  TAG = "[ClientRoutesManager]";

  /**
   * Service root route object.
   * Contains all service routes as nested.
   */
  rootRouteObject;

  /**
   * Service routes segments.
   */
  Segments;

  /**
   * Initializes {@link ClientRoutesManager} instance with the provided configuration.
   *
   * @param rootRouteObjectConfig - Root route object config, that contains all information about service routing.
   * @param routesSegments - Service routes segments.
   */
  initialize(rootRouteObjectConfig, routesSegments) {
    this.Segments = routesSegments;
    this.rootRouteObject = transformConfigToRouteObject(rootRouteObjectConfig);
  }

  /**
   * Get particular route, that is located under a specified location in routes tree.
   *
   * @param args - List of route segments, that are representing path over the routes tree.
   */
  getRoute(...args) {
    if (!this.rootRouteObject) {
      throw new Error(
        `${this.TAG} Routes manager is not initialized. Call 'initialize()' method first.`,
      );
    }

    const getRouteUnderSegment = (route, segmentIndex) => {
      if (segmentIndex > args.length - 1) {
        return route;
      }

      if (route.nested === undefined || route.nested[args[segmentIndex]] === undefined) {
        throw new Error(
          `${this.TAG} Route '/${args.join("/")}' does not exist at a segment '${
            args[segmentIndex]
          }.'`,
        );
      }

      return getRouteUnderSegment(route.nested[args[segmentIndex]], segmentIndex + 1);
    };

    return getRouteUnderSegment(this.rootRouteObject, 0);
  }

  /**
   * Get full URL of a route, that is located under a specified location in routes tree.
   *
   * @param args - List of route segments, that are representing path over tree routes.
   */
  getFullRouteUrl(...args) {
    const route = this.getRoute(...args);

    return `${settings.Application.Url}${route.pathname}`;
  }
}
