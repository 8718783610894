/**
 * Set of the possible notifications statuses.
 */
export const NotificationStatuses = {
  /**
   * Success notification.
   */
  Success: "Success",

  /**
   * Information notification.
   */
  Information: "Information",

  /**
   * Error notification.
   */
  Error: "Error",

  /**
   * Warning notification.
   */
  Warning: "Warning",
};
