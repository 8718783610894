/**
 * Name of the option in the survey, responsible for specifying other variant then the suggested.
 */
export const OTHER_OPTION = "Other";

/**
 * Field name postfix, means that the current field is the comment field.
 */
export const COMMENT_FIELD_NAME_PART = "Comment";

/**
 * List of form field names to use.
 */
export const FormFieldNames = {
  /**
   * Card details.
   */
  CardDetails: "cardDetails",

  /**
   * Email confirmation.
   */
  ConfirmEmail: "confirmEmail",

  /**
   * New password confirmation.
   */
  ConfirmNewPassword: "confirmNewPassword",

  /**
   * Password confirmation.
   */
  ConfirmPassword: "confirmPassword",

  /**
   * Current password.
   */
  CurrentPassword: "currentPassword",

  /**
   * Email.
   */
  Email: "email",

  /**
   * First name.
   */
  FirstName: "firstName",

  /**
   * Last name.
   */
  LastName: "lastName",

  /**
   * Message.
   */
  Message: "message",

  /**
   * Month.
   */
  Month: "month",

  /**
   * New password.
   */
  NewPassword: "newPassword",

  /**
   * Password.
   */
  Password: "password",

  /**
   * Phone number.
   */
  PhoneNumber: "phoneNumber",

  /**
   * Subject.
   */
  Subject: "subject",

  /**
   * Terms of service confirmation.
   */
  TosConfirmation: "tosConfirmation",

  /**
   * Verification code.
   */
  VerificationCode: "verificationCode",

  /**
   * Week.
   */
  Week: "week",

  /**
   * Year.
   */
  Year: "year",
};
