import { encrypt } from "@aft/client-services/crypto";
import { FormFieldNames } from "@aft/client-services/forms";

import {
  SET_CURRENT_USER_DATA,
  GET_CURRENT_USER_REQUEST,
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER_FAILURE,
  UPDATE_CURRENT_USER_INFO_REQUEST,
  UPDATE_CURRENT_USER_INFO_SUCCESS,
  UPDATE_CURRENT_USER_INFO_FAILURE,
  UPDATE_CURRENT_USER_PHONE_NUMBER_REQUEST,
  UPDATE_CURRENT_USER_PHONE_NUMBER_SUCCESS,
  UPDATE_CURRENT_USER_PHONE_NUMBER_FAILURE,
  UPDATE_CURRENT_USER_EMAIL_REQUEST,
  UPDATE_CURRENT_USER_EMAIL_SUCCESS,
  UPDATE_CURRENT_USER_EMAIL_FAILURE,
  CONFIRM_CURRENT_USER_EMAIL_UPDATE_REQUEST,
  CONFIRM_CURRENT_USER_EMAIL_UPDATE_SUCCESS,
  CONFIRM_CURRENT_USER_EMAIL_UPDATE_FAILURE,
  UPDATE_CURRENT_USER_PASSWORD_REQUEST,
  UPDATE_CURRENT_USER_PASSWORD_SUCCESS,
  UPDATE_CURRENT_USER_PASSWORD_FAILURE,
} from "./actionTypes";

/**
 * Template action for setting user data.
 *
 * @param type - Action type.
 * @param user - User info.
 */
const setCurrentUserDataTemplateAction = (type, user) => ({
  type,
  user,
});

/**
 * Set data of the current user to a store.
 *
 * @param user - User info.
 */
export const setCurrentUserData = (user) =>
  setCurrentUserDataTemplateAction(SET_CURRENT_USER_DATA, user);

/**
 * Current user info request action.
 */
export const getCurrentUserRequest = () => ({
  type: GET_CURRENT_USER_REQUEST,
});

/**
 * Callback action for successful user info request attempt.
 *
 * @param user - User info.
 */
export const getCurrentUserSuccess = (user) =>
  setCurrentUserDataTemplateAction(GET_CURRENT_USER_SUCCESS, user);

/**
 * Callback action for failed  user info request attempt.
 *
 * @param error - Error message.
 */
export const getCurrentUserFailure = (error) => ({
  type: GET_CURRENT_USER_FAILURE,
  error,
});

/**
 * Current user info update request action.
 *
 * @param data - User data to be updated.
 */
export const updateCurrentUserInfoRequest = (data) => ({
  type: UPDATE_CURRENT_USER_INFO_REQUEST,
  data,
});

/**
 * Callback action for successful user info update request attempt.
 *
 * @param user - User info.
 */
export const updateCurrentUserInfoSuccess = (user) =>
  setCurrentUserDataTemplateAction(UPDATE_CURRENT_USER_INFO_SUCCESS, user);

/**
 * Callback action for failed user info update request attempt.
 *
 * @param error - Error message.
 */
export const updateCurrentUserInfoFailure = (error) => ({
  type: UPDATE_CURRENT_USER_INFO_FAILURE,
  error,
});

/**
 * Current user phone number update request action.
 *
 * @param verificationCode - Verification code for the phone number update.
 */
export const updateCurrentUserPhoneNumberRequest = (verificationCode) => ({
  type: UPDATE_CURRENT_USER_PHONE_NUMBER_REQUEST,
  verificationCode,
});

/**
 * Callback action for successful user phone number update request attempt.
 *
 * @param user - User info.
 */
export const updateCurrentUserPhoneNumberSuccess = (user) =>
  setCurrentUserDataTemplateAction(UPDATE_CURRENT_USER_PHONE_NUMBER_SUCCESS, user);

/**
 * Callback action for failed user phone number update request attempt.
 *
 * @param error - Error message.
 */
export const updateCurrentUserPhoneNumberFailure = (error) => ({
  type: UPDATE_CURRENT_USER_PHONE_NUMBER_FAILURE,
  error,
});

/**
 * Current user email update request action.
 *
 * @param email - User email to be used.
 * @param callbackUrl - URL for callback token usage.
 */
export const updateCurrentUserEmailRequest = (email, callbackUrl) => ({
  type: UPDATE_CURRENT_USER_EMAIL_REQUEST,
  email,
  callbackUrl,
});

/**
 * Callback action for successful user email update request attempt.
 */
export const updateCurrentUserEmailSuccess = () => ({
  type: UPDATE_CURRENT_USER_EMAIL_SUCCESS,
});

/**
 * Callback action for failed user email update request attempt.
 *
 * @param error - Error message.
 */
export const updateCurrentUserEmailFailure = (error) => ({
  type: UPDATE_CURRENT_USER_EMAIL_FAILURE,
  error,
});

/**
 * Request action for confirmation of changing email for the current user.
 *
 * @param token - Token to be user for email change confirmation.
 */
export const confirmCurrentUserEmailUpdateRequest = (token) => ({
  type: CONFIRM_CURRENT_USER_EMAIL_UPDATE_REQUEST,
  token,
});

/**
 * Callback action for successful confirmation of changing email for the current user.
 *
 * @param user - User info.
 */
export const confirmCurrentUserEmailUpdateSuccess = (user) =>
  setCurrentUserDataTemplateAction(CONFIRM_CURRENT_USER_EMAIL_UPDATE_SUCCESS, user);

/**
 * Callback action for failed confirmation of changing email for the current user.
 *
 * @param error - Error message.
 */
export const confirmCurrentUserEmailUpdateFailure = (error) => ({
  type: CONFIRM_CURRENT_USER_EMAIL_UPDATE_FAILURE,
  error,
});

/**
 * Current user password update request action.
 *
 * @param data - Data for the password update.
 */
export const updateCurrentUserPasswordRequest = (data) => ({
  type: UPDATE_CURRENT_USER_PASSWORD_REQUEST,
  data: {
    ...data,
    [FormFieldNames.CurrentPassword]: encrypt(data[FormFieldNames.CurrentPassword]),
    [FormFieldNames.NewPassword]: encrypt(data[FormFieldNames.NewPassword]),
  },
});

/**
 * Callback action for successful user password update request attempt.
 *
 * @param user - User info.
 */
export const updateCurrentUserPasswordSuccess = (user) =>
  setCurrentUserDataTemplateAction(UPDATE_CURRENT_USER_PASSWORD_SUCCESS, user);

/**
 * Callback action for failed user password update request attempt.
 *
 * @param error - Error message.
 */
export const updateCurrentUserPasswordFailure = (error) => ({
  type: UPDATE_CURRENT_USER_PASSWORD_FAILURE,
  error,
});
