/**
 * Set of the request methods to be used upon API calls.
 */
const RequestMethods = {
  /**
   * Get request.
   */
  Get: "get",

  /**
   * Post request.
   */
  Post: "post",

  /**
   * Put request.
   */
  Put: "put",

  /**
   * Delete request.
   */
  Delete: "delete",
};

module.exports = {
  RequestMethods,
};
