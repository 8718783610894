/**
 * Selects phone number from the store.
 *
 * @param store - Combined store.
 * @param store.phone - Phone store.
 */
export const selectPhoneNumber = ({ phone }) => phone.phoneNumber;

/**
 * Selects phone verification token from the store.
 *
 * @param store - Combined store.
 * @param store.phone - Phone store.
 */
export const selectPhoneVerificationToken = ({ phone }) => phone.phoneVerificationToken;
