const PREFIX = `AUTHORIZATION/`;

export const SET_SIGN_IN_REASON = `${PREFIX}SET_SIGN_IN_REASON`;

export const SIGN_IN_REQUEST = `${PREFIX}SIGN_IN_REQUEST`;
export const SIGN_IN_SUCCESS = `${PREFIX}SIGN_IN_SUCCESS`;
export const SIGN_IN_FAILURE = `${PREFIX}SIGN_IN_FAILURE`;

export const SESSION_CONTINUE_REQUEST = `${PREFIX}SESSION_CONTINUE_REQUEST`;
export const SESSION_CONTINUE_SUCCESS = `${PREFIX}SESSION_CONTINUE_SUCCESS`;
export const SESSION_CONTINUE_FAILURE = `${PREFIX}SESSION_CONTINUE_FAILURE`;

export const SIGN_UP_REQUEST = `${PREFIX}SIGN_UP_REQUEST`;
export const SIGN_UP_SUCCESS = `${PREFIX}SIGN_UP_SUCCESS`;
export const SIGN_UP_FAILURE = `${PREFIX}SIGN_UP_FAILURE`;

export const PHONE_VERIFICATION_REQUEST = `${PREFIX}PHONE_VERIFICATION_REQUEST`;
export const PHONE_VERIFICATION_SUCCESS = `${PREFIX}PHONE_VERIFICATION_SUCCESS`;
export const PHONE_VERIFICATION_FAILURE = `${PREFIX}PHONE_VERIFICATION_FAILURE`;

export const CONFIRM_EMAIL_REQUEST = `${PREFIX}CONFIRM_EMAIL_REQUEST`;
export const CONFIRM_EMAIL_SUCCESS = `${PREFIX}CONFIRM_EMAIL_SUCCESS`;
export const CONFIRM_EMAIL_FAILURE = `${PREFIX}CONFIRM_EMAIL_FAILURE`;

export const RESET_PASSWORD_REQUEST_REQUEST = `${PREFIX}RESET_PASSWORD_REQUEST_REQUEST`;
export const RESET_PASSWORD_REQUEST_SUCCESS = `${PREFIX}RESET_PASSWORD_REQUEST_SUCCESS`;
export const RESET_PASSWORD_REQUEST_FAILURE = `${PREFIX}RESET_PASSWORD_REQUEST_FAILURE`;

export const UPDATE_PASSWORD_REQUEST = `${PREFIX}UPDATE_PASSWORD_REQUEST`;
export const UPDATE_PASSWORD_SUCCESS = `${PREFIX}UPDATE_PASSWORD_SUCCESS`;
export const UPDATE_PASSWORD_FAILURE = `${PREFIX}UPDATE_PASSWORD_FAILURE`;

export const SIGN_OUT_REQUEST = `${PREFIX}SIGN_OUT_REQUEST`;
export const SIGN_OUT_SUCCESS = `${PREFIX}SIGN_OUT_SUCCESS`;
export const SIGN_OUT_FAILURE = `${PREFIX}SIGN_OUT_FAILURE`;
