import React, { Fragment, memo, useMemo } from "react";

import clsx from "clsx";
import { useTranslation } from "next-i18next";
import { Container, Row, Button } from "react-bootstrap";
import { connect } from "react-redux";

import { notificationsActions, NotificationStatuses } from "@aft/client-modules/notifications";

const NotificationsComponent = memo(({ notifications, closeNotification }) => {
  const { t } = useTranslation("common");

  return useMemo(
    () => (
      <Row noGutters className="flex-column flex-nowrap">
        {notifications.map(({ id, message, status }) => {
          let colors;

          switch (status) {
            case NotificationStatuses.Info:
              colors = "bg-primary text-white";
              break;
            case NotificationStatuses.Error:
              colors = "bg-danger text-white";
              break;
            case NotificationStatuses.Warning:
              colors = "bg-secondary text-white";
              break;
            case NotificationStatuses.Success:
            default:
              colors = "bg-success text-white";
              break;
          }

          return (
            <Button
              key={id}
              variant="not-styled"
              className={clsx(colors, "py-3 shadow-sm")}
              onClick={() => {
                closeNotification(id);
              }}
            >
              <Container fluid="xl">
                <p className="text-center">
                  {t(message || "")
                    .split("\n")
                    .map((notificationLine, lineIndex) => [
                      <Fragment key={lineIndex * 2}>{notificationLine}</Fragment>,
                      <br key={lineIndex * 2 + 1} />,
                    ])}
                </p>
              </Container>
            </Button>
          );
        })}
      </Row>
    ),
    [t, notifications, closeNotification],
  );
});

const mapStateToProps = ({ notifications }) => ({ notifications });

/**
 * Notifications component.
 * Responsible for notifications management.
 */
export const Notifications = connect(mapStateToProps, {
  closeNotification: notificationsActions.closeNotification,
})(NotificationsComponent);
