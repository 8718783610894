/**
 * Set of AFT services.
 */
const AftServices = {
  /**
   * Server.
   */
  Server: "Server",

  /**
   * Dashboard client.
   */
  DashboardClient: "DashboardClient",

  /**
   * Website.
   */
  Website: "Website",
};

module.exports = {
  AftServices,
};
