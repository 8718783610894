/**
 * Server API routes.
 */
export const Routes = {
  /**
   * Authorization.
   */
  Authorization: {
    path: "/authorization",
    serverRouterPath: "/",

    /**
     * Sign in.
     */
    SignIn: {
      path: "/authorization/sign-in",
      serverRouterPath: "/sign-in",
    },

    /**
     * Session.
     */
    Session: {
      path: "/authorization/session",
      serverRouterPath: "/session",

      /**
       * Continue session.
       */
      Continue: {
        path: "/authorization/session/continue",
        serverRouterPath: "/session/continue",
      },
    },

    /**
     * Sign out.
     */
    SignOut: {
      path: "/authorization/sign-out",
      serverRouterPath: "/sign-out",
    },

    /**
     * Email.
     */
    Email: {
      path: "/authorization/email",
      serverRouterPath: "/email",

      /**
       * Confirm email.
       */
      Confirm: {
        path: "/authorization/email/confirm",
        serverRouterPath: "/email/confirm",
      },
    },

    /**
     * Phone.
     */
    Phone: {
      path: "/authorization/phone",
      serverRouterPath: "/phone",

      /**
       * Phone verification.
       */
      Verification: {
        path: "/authorization/phone/verification",
        serverRouterPath: "/phone/verification",
      },
    },

    /**
     * Password.
     */
    Password: {
      path: "/authorization/password",
      serverRouterPath: "/password",

      /**
       * Reset password.
       */
      Reset: {
        path: "/authorization/password/reset",
        serverRouterPath: "/password/reset",
      },
    },
  },

  /**
   * Holdings.
   */
  Holdings: {
    path: "/holdings",
    serverRouterPath: "/",

    /**
     * Holdings data dates range.
     */
    DatesRange: {
      path: "/holdings/dates-range",
      serverRouterPath: "/dates-range",
    },
  },

  /**
   * Phone.
   */
  Phone: {
    path: "/phone",
    serverRouterPath: "/",

    /**
     * Validate phone number.
     */
    Validate: {
      path: "/phone/validate",
      serverRouterPath: "/validate",
    },

    /**
     * Request phone verification.
     */
    RequestVerification: {
      path: "/phone/request-verification",
      serverRouterPath: "/request-verification",
    },
  },

  /**
   * Stripe.
   */
  Stripe: {
    path: "/stripe",
    serverRouterPath: "/",

    /**
     * Stripe webhook.
     */
    Webhook: {
      path: "/stripe/webhook",
      serverRouterPath: "/webhook",
    },
  },

  /**
   * Subscription.
   */
  Subscription: {
    path: "/subscription",
    serverRouterPath: "/",

    /**
     * Subscription prices.
     */
    Prices: {
      path: "/subscription/prices",
      serverRouterPath: "/prices",
    },
  },

  /**
   * Support.
   */
  Support: {
    path: "/support",
    serverRouterPath: "/",

    /**
     * Technical support.
     */
    Technical: {
      path: "/support/technical",
      serverRouterPath: "/technical",
    },
  },

  /**
   * Survey.
   */
  Survey: {
    path: "/survey",
    serverRouterPath: "/",
  },

  /**
   * Users.
   */
  Users: {
    path: "/users",
    serverRouterPath: "/",

    /**
     * Validate user.
     */
    Validate: {
      path: "/users/validate",
      serverRouterPath: "/validate",
    },

    /**
     * Current user.
     */
    Current: {
      path: "/users/current",
      serverRouterPath: "/current",

      /**
       * Email.
       */
      Email: {
        path: "/users/current/email",
        serverRouterPath: "/current/email",

        /**
         * Confirm email.
         */
        Confirm: {
          path: "/users/current/email/confirm",
          serverRouterPath: "/current/email/confirm",
        },
      },

      /**
       * Info.
       */
      Info: {
        path: "/users/current/info",
        serverRouterPath: "/current/info",
      },

      /**
       * Phone.
       */
      Phone: {
        path: "/users/current/phone",
        serverRouterPath: "/current/phone",
      },

      /**
       * Password.
       */
      Password: {
        path: "/users/current/password",
        serverRouterPath: "/current/password",
      },

      /**
       * Payment method.
       */
      PaymentMethod: {
        path: "/users/current/payment-method",
        serverRouterPath: "/current/payment-method",
      },

      /**
       * Subscription.
       */
      Subscription: {
        path: "/users/current/subscription",
        serverRouterPath: "/current/subscription",

        /**
         * Subscription payments.
         */
        Payments: {
          path: "/users/current/subscription/payments",
          serverRouterPath: "/current/subscription/payments",

          /**
           * Upcoming subscription payment.
           */
          Upcoming: {
            path: "/users/current/subscription/payments/upcoming",
            serverRouterPath: "/current/subscription/payments/upcoming",
          },
        },

        /**
         * Cancel subscription.
         */
        Cancel: {
          path: "/users/current/subscription/cancel",
          serverRouterPath: "/current/subscription/cancel",
        },
      },
    },
  },
};
