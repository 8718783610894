import { camelCase } from "change-case";

/**
 * Async action stages.
 */
export const AsyncActionStages = {
  /**
   * Request.
   */
  Request: "REQUEST",

  /**
   * Success.
   */
  Success: "SUCCESS",

  /**
   * Failure.
   */
  Failure: "FAILURE",
};

/**
 * Default values of the method states.
 * Makes it possible to look over each saga function execution.
 */
export const AsyncActionStateDefaultValues = {
  /**
   * Indicates, whether request ended with a successful response.
   */
  success: false,

  /**
   * Error, returned in a case of failed request.
   */
  error: undefined,

  /**
   * Indicates, whether request is currently executing.
   */
  loading: false,

  /**
   * Indicates whether current attempt is retry or not.
   */
  isRetry: false,
};

/**
 * Converts action type to a method name, for watching over its execution state.
 *
 * @param actionType - Action type to convert.
 */
export const actionTypeToMethodName = (actionType) =>
  camelCase(actionType.split("/")[1].split("_").slice(0, -1).join("_"));

/**
 * Validates, whether specified action type corresponds to an async action,
 * and returns action stage.
 *
 * @param actionType - Action type.
 */
export const getAsyncActionStage = (actionType) => {
  const stageCandidate = actionType.split("_").slice(-1)[0];

  return Object.values(AsyncActionStages).includes(stageCandidate) ? stageCandidate : undefined;
};
