/**
 * Regular expression for email validation.
 */
const EMAIL_REGEXP = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

// TODO: Inject password length setting.
/**
 * Regular expression for password validation.
 */
const PASSWORD_REGEXP = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!-/:-@[-`{-~])[!-/:-@[-`{-~a-zA-Z\d]{8,100}$/;

/**
 * Regular expression for verification code validation.
 */
const VERIFICATION_CODE_REGEXP = /^[0-9]*$/;

module.exports = {
  EMAIL_REGEXP,
  PASSWORD_REGEXP,
  VERIFICATION_CODE_REGEXP,
};
