import {
  SET_PHONE_NUMBER,
  PHONE_VALIDATION_REQUEST,
  PHONE_VALIDATION_SUCCESS,
  PHONE_VALIDATION_FAILURE,
  REQUEST_PHONE_VERIFICATION_REQUEST,
  REQUEST_PHONE_VERIFICATION_SUCCESS,
  REQUEST_PHONE_VERIFICATION_FAILURE,
} from "./actionTypes";

/**
 * Set new phone number to a store.
 *
 * @param phoneNumber - New phone number.
 */
export const setPhoneNumber = (phoneNumber) => ({
  type: SET_PHONE_NUMBER,
  phoneNumber,
});

/**
 * Phone validation request.
 *
 * @param isRetry - Indicates whether current attempt is retry or not.
 */
export const phoneValidationRequest = (isRetry = false) => ({
  type: PHONE_VALIDATION_REQUEST,
  isRetry,
});

/**
 * Callback action for successful phone validation attempt.
 *
 * @param phoneVerificationToken - Phone verification token.
 */
export const phoneValidationSuccess = (phoneVerificationToken) => ({
  type: PHONE_VALIDATION_SUCCESS,
  phoneVerificationToken,
});

/**
 * Callback action for failed phone validation attempt.
 *
 * @param error - Error message.
 */
export const phoneValidationFailure = (error) => ({
  type: PHONE_VALIDATION_FAILURE,
  error,
});

/**
 * Request phone verification.
 *
 * @param isRetry - Indicates whether current attempt is retry or not.
 */
export const requestPhoneVerificationRequest = (isRetry) => ({
  type: REQUEST_PHONE_VERIFICATION_REQUEST,
  isRetry,
});

/**
 * Callback action for successful phone verification request attempt.
 *
 * @param phoneVerificationToken - Phone verification token.
 */
export const requestPhoneVerificationSuccess = (phoneVerificationToken) => ({
  type: REQUEST_PHONE_VERIFICATION_SUCCESS,
  phoneVerificationToken,
});

/**
 * Callback action for failed phone verification request attempt.
 *
 * @param error - Error message.
 */
export const requestPhoneVerificationFailure = (error) => ({
  type: REQUEST_PHONE_VERIFICATION_FAILURE,
  error,
});
