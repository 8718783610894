import { takeEvery, select, put, delay } from "redux-saga/effects";

import { OPEN_NOTIFICATION } from "./actionTypes";
import { closeNotification } from "./actions";
import { selectNotification } from "./selectors";

function* openNotification({ id, options: { autoDismiss } }) {
  if (autoDismiss > 0) {
    yield delay(autoDismiss);

    const notification = yield select(selectNotification, id);

    if (notification) {
      yield put(closeNotification(id));
    }
  }
}

/**
 * Root saga of the notifications module.
 */
export function* notificationsSaga() {
  yield takeEvery(OPEN_NOTIFICATION, openNotification);
}
