import { call, put, select, takeLatest } from "redux-saga/effects";

import { applicationSagas } from "@aft/client-modules/application";
import { authorizationActions, SignInReasons } from "@aft/client-modules/authorization";
import { phoneSelectors } from "@aft/client-modules/phone";
import { serverApiClient } from "@aft/server/apiClient";

import { sessionActions } from "../session";

import {
  GET_CURRENT_USER_REQUEST,
  UPDATE_CURRENT_USER_INFO_REQUEST,
  UPDATE_CURRENT_USER_PHONE_NUMBER_REQUEST,
  UPDATE_CURRENT_USER_EMAIL_REQUEST,
  CONFIRM_CURRENT_USER_EMAIL_UPDATE_REQUEST,
  UPDATE_CURRENT_USER_PASSWORD_REQUEST,
} from "./actionTypes";
import {
  getCurrentUserSuccess,
  getCurrentUserFailure,
  updateCurrentUserInfoSuccess,
  updateCurrentUserInfoFailure,
  updateCurrentUserPhoneNumberSuccess,
  updateCurrentUserPhoneNumberFailure,
  updateCurrentUserEmailSuccess,
  updateCurrentUserEmailFailure,
  confirmCurrentUserEmailUpdateSuccess,
  confirmCurrentUserEmailUpdateFailure,
  updateCurrentUserPasswordSuccess,
  updateCurrentUserPasswordFailure,
} from "./actions";

function* getCurrentUser() {
  try {
    const user = yield call(serverApiClient.users.getCurrentUser);

    yield put(getCurrentUserSuccess(user));
  } catch (err) {
    yield put(getCurrentUserFailure(err.message));
    yield call(applicationSagas.handleApiError, err);
  }
}

function* updateCurrentUserInfo({ data }) {
  try {
    const user = yield call(serverApiClient.users.updateCurrentUserInfo, data);

    yield put(updateCurrentUserInfoSuccess(user));
  } catch (err) {
    yield put(updateCurrentUserInfoFailure(err.message));
    yield call(applicationSagas.handleApiError, err);
  }
}

function* updateCurrentUserPhoneNumber({ verificationCode }) {
  try {
    const phoneNumber = yield select(phoneSelectors.selectPhoneNumber);
    const phoneVerificationToken = yield select(phoneSelectors.selectPhoneVerificationToken);

    yield call(serverApiClient.users.updateCurrentUserPhoneNumber, {
      phoneNumber,
      phoneVerificationToken,
      verificationCode,
    });

    yield put(updateCurrentUserPhoneNumberSuccess());

    yield put(sessionActions.clearSession());
    yield put(authorizationActions.setSignInReason(SignInReasons.PhoneNumberUpdated));
  } catch (err) {
    yield put(updateCurrentUserPhoneNumberFailure(err.message));
    yield call(applicationSagas.handleApiError, err);
  }
}

function* updateCurrentUserEmail({ email, callbackUrl }) {
  try {
    yield call(serverApiClient.users.updateCurrentUserEmail, email, callbackUrl);

    yield put(updateCurrentUserEmailSuccess());
  } catch (err) {
    yield put(updateCurrentUserEmailFailure(err.message));
    yield call(applicationSagas.handleApiError, err);
  }
}

function* confirmCurrentUserEmailUpdate({ token }) {
  try {
    yield call(serverApiClient.users.confirmCurrentUserEmailUpdate, token);

    yield put(confirmCurrentUserEmailUpdateSuccess());

    yield put(sessionActions.clearSession());
    yield put(authorizationActions.setSignInReason(SignInReasons.EmailUpdated));
  } catch (err) {
    yield put(confirmCurrentUserEmailUpdateFailure(err.message));
    yield call(applicationSagas.handleApiError, err);
  }
}

function* updateCurrentUserPassword({ data }) {
  try {
    yield call(serverApiClient.users.updateCurrentUserPassword, data);

    yield put(updateCurrentUserPasswordSuccess());

    yield put(sessionActions.clearSession());
    yield put(authorizationActions.setSignInReason(SignInReasons.PasswordUpdated));
  } catch (err) {
    yield put(updateCurrentUserPasswordFailure(err.message));
    yield call(applicationSagas.handleApiError, err);
  }
}

/**
 * Root saga of the account module.
 */
export function* accountSaga() {
  yield takeLatest(GET_CURRENT_USER_REQUEST, getCurrentUser);
  yield takeLatest(UPDATE_CURRENT_USER_INFO_REQUEST, updateCurrentUserInfo);
  yield takeLatest(UPDATE_CURRENT_USER_PHONE_NUMBER_REQUEST, updateCurrentUserPhoneNumber);
  yield takeLatest(UPDATE_CURRENT_USER_EMAIL_REQUEST, updateCurrentUserEmail);
  yield takeLatest(CONFIRM_CURRENT_USER_EMAIL_UPDATE_REQUEST, confirmCurrentUserEmailUpdate);
  yield takeLatest(UPDATE_CURRENT_USER_PASSWORD_REQUEST, updateCurrentUserPassword);
}
