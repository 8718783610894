/**
 * Set of possible reasons, user is have to perform a sign in action.
 */
export const SignInReasons = {
  /**
   * Account was successfully created.
   */
  AccountCreated: "Account created",

  /**
   * Email was successfully updated.
   */
  EmailUpdated: "Email updated",

  /**
   * Password was successfully updated.
   */
  PasswordUpdated: "Password updated",

  /**
   * Phone number was successfully updated.
   */
  PhoneNumberUpdated: "Phone number updated",
};
