import { call, put, select, takeLatest } from "redux-saga/effects";

import { accountActions } from "@aft/client-modules/account";
import { applicationSagas } from "@aft/client-modules/application";
import { phoneSelectors } from "@aft/client-modules/phone";
import { sessionActions } from "@aft/client-modules/session";
import { serverApiClient } from "@aft/server/apiClient";

import {
  SIGN_IN_REQUEST,
  SESSION_CONTINUE_REQUEST,
  SIGN_UP_REQUEST,
  PHONE_VERIFICATION_REQUEST,
  CONFIRM_EMAIL_REQUEST,
  RESET_PASSWORD_REQUEST_REQUEST,
  UPDATE_PASSWORD_REQUEST,
  SIGN_OUT_REQUEST,
} from "./actionTypes";
import {
  setSignInReason,
  signInSuccess,
  signInFailure,
  sessionContinueSuccess,
  sessionContinueFailure,
  signUpSuccess,
  signUpFailure,
  signOutSuccess,
  signOutFailure,
  phoneVerificationSuccess,
  phoneVerificationFailure,
  confirmEmailSuccess,
  confirmEmailFailure,
  resetPasswordRequestSuccess,
  resetPasswordRequestFailure,
  updatePasswordSuccess,
  updatePasswordFailure,
} from "./actions";
import { SignInReasons } from "./constants";
import { selectSignUpData } from "./selectors";

function* signIn({ credentials }) {
  try {
    const user = yield call(serverApiClient.authorization.signIn, credentials);

    yield put(accountActions.setCurrentUserData(user));
    yield put(signInSuccess());
  } catch (err) {
    yield put(signInFailure(err.message));
    yield call(applicationSagas.handleApiError, err);
  }
}

function* sessionContinue() {
  try {
    const user = yield call(serverApiClient.authorization.sessionContinue);

    yield put(accountActions.setCurrentUserData(user));
    yield put(sessionContinueSuccess());
  } catch (err) {
    yield put(sessionContinueFailure(err.message, true));
    yield call(applicationSagas.handleApiError, err);
  }
}

function* signUp({ signUpData }) {
  try {
    yield call(serverApiClient.users.validate, signUpData);

    yield put(signUpSuccess(signUpData));
  } catch (err) {
    yield put(signUpFailure(err.message));
    yield call(applicationSagas.handleApiError, err);
  }
}

function* phoneVerification({ verificationCode, callbackUrl }) {
  try {
    const signUpData = yield select(selectSignUpData);
    const phoneVerificationToken = yield select(phoneSelectors.selectPhoneVerificationToken);

    yield call(
      serverApiClient.authorization.phoneVerification,
      phoneVerificationToken,
      verificationCode,
      signUpData,
      callbackUrl,
    );

    yield put(phoneVerificationSuccess());
  } catch (err) {
    yield put(phoneVerificationFailure(err.message));
    yield call(applicationSagas.handleApiError, err);
  }
}

function* confirmEmail({ emailConfirmationToken }) {
  try {
    const user = yield call(serverApiClient.authorization.confirmEmail, emailConfirmationToken);

    yield put(accountActions.setCurrentUserData(user));
    yield put(setSignInReason(SignInReasons.AccountCreated));
    yield put(confirmEmailSuccess());
  } catch (err) {
    yield put(confirmEmailFailure(err.message));
    yield call(applicationSagas.handleApiError, err);
  }
}

function* resetPasswordRequest({ email, callbackUrl }) {
  try {
    yield call(serverApiClient.authorization.resetPasswordRequest, email, callbackUrl);

    yield put(resetPasswordRequestSuccess());
  } catch (err) {
    yield put(resetPasswordRequestFailure(err.message));
    yield call(applicationSagas.handleApiError, err);
  }
}

function* updatePassword({ password, token }) {
  try {
    yield call(serverApiClient.authorization.updatePassword, token, password);

    yield put(updatePasswordSuccess(SignInReasons.PasswordUpdated));
  } catch (err) {
    yield put(updatePasswordFailure(err.message));
    yield call(applicationSagas.handleApiError, err);
  }
}

function* signOut({ reason }) {
  try {
    yield call(serverApiClient.authorization.signOut);

    yield put(sessionActions.clearSession());

    yield put(signOutSuccess(reason));
  } catch (err) {
    yield put(signOutFailure(err.message));
    yield call(applicationSagas.handleApiError, err);
  }
}

/**
 * Root saga of the authorization module.
 */
export function* authorizationSaga() {
  yield takeLatest(SIGN_IN_REQUEST, signIn);
  yield takeLatest(SESSION_CONTINUE_REQUEST, sessionContinue);
  yield takeLatest(SIGN_UP_REQUEST, signUp);
  yield takeLatest(PHONE_VERIFICATION_REQUEST, phoneVerification);
  yield takeLatest(CONFIRM_EMAIL_REQUEST, confirmEmail);
  yield takeLatest(RESET_PASSWORD_REQUEST_REQUEST, resetPasswordRequest);
  yield takeLatest(UPDATE_PASSWORD_REQUEST, updatePassword);
  yield takeLatest(SIGN_OUT_REQUEST, signOut);
}
