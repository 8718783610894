import { isPossiblePhoneNumber } from "react-phone-number-input";

import { settings } from "@aft/client-settings";
import {
  EMAIL_REGEXP,
  PASSWORD_REGEXP,
  VERIFICATION_CODE_REGEXP,
} from "@aft/constants/general/validation";

import { textToCommentFieldName, textToOtherOptionFieldName } from "./helpers";

/**
 * Creates validation function for the required field.
 *
 * @param fieldName - Field name.
 *
 * @returns Validation function, that returns 'undefined' for no error,
 * or error message in another case.
 */
export const createRequiredFieldValidator = (fieldName) => (t, values) => {
  let error;

  const value = values[fieldName];

  if (value === undefined || value === "") {
    error = t("Validation.Required");
  }

  return error ? { [fieldName]: error } : undefined;
};

/**
 * Creates validation function for the phone number input field.
 *
 * @param fieldName - Field name.
 *
 * @returns Validation function, that returns 'undefined' for no error,
 * or error message in another case.
 */
export const createPhoneNumberFieldValidator = (fieldName) => (t, values) => {
  let error;

  const value = values[fieldName];

  if (value === undefined || value === "") {
    error = t("Validation.Required");
  } else if (!isPossiblePhoneNumber(value)) {
    error = t("Validation.PhoneNumber.Format");
  }

  return error ? { [fieldName]: error } : undefined;
};

/**
 * Creates validation function for the email input field.
 *
 * @param fieldName - Field name.
 *
 * @returns Validation function, that returns 'undefined' for no error,
 * or error message in another case.
 */
export const createEmailFieldValidator = (fieldName) => (t, values) => {
  let error;

  const email = values[fieldName];

  if (email === undefined || email === "") {
    error = t("Validation.Required");
  } else if (EMAIL_REGEXP.test(email) === false) {
    error = t("Validation.Email.Format");
  }

  return error ? { [fieldName]: error } : undefined;
};

/**
 * Creates validation function for the email confirmation input field.
 *
 * @param emailConfirmationFieldName - Name of the field, that contains email confirmation value.
 * @param emailFieldName - Name of the field, that contains original email value.
 *
 * @returns Validation function, that returns 'undefined' for no error,
 * or error message in another case.
 */
export const createEmailConfirmationFieldValidator = (
  emailConfirmationFieldName,
  emailFieldName,
) => (t, values) => {
  let error;

  const email = values[emailFieldName];
  const confirmEmail = values[emailConfirmationFieldName];

  const confirmEmailError = createEmailFieldValidator(emailConfirmationFieldName)(t, values);
  if (confirmEmailError) {
    return confirmEmailError;
  }
  if (email !== confirmEmail) {
    error = t("Validation.Email.Mismatch");
  }

  return error ? { [emailConfirmationFieldName]: error } : undefined;
};

/**
 * Creates validation function for the email input field.
 *
 * @param fieldName - Field name.
 *
 * @returns Validation function, that returns 'undefined' for no error,
 * or error message in another case.
 */
export const createPasswordFieldValidator = (fieldName) => (t, values) => {
  let error;

  const password = values[fieldName];

  if (password === undefined || password === "") {
    error = t("Validation.Required");
  } else if (password.length < settings.Password.Length.Minimal) {
    error = t("Validation.Password.TooShort", { length: settings.Password.Length.Minimal });
  } else if (password.length > settings.Password.Length.Maximal) {
    error = t("Validation.Password.TooLong", { length: settings.Password.Length.Maximal });
  } else if (PASSWORD_REGEXP.test(password) === false) {
    error = t("Validation.Password.Format");
  }

  return error ? { [fieldName]: error } : undefined;
};

/**
 * Creates validation function for the password confirmation input field.
 *
 * @param passwordConfirmationFieldName - Name of the field, that contains email confirmation value.
 * @param passwordFieldName - Name of the field, that contains original email value.
 *
 * @returns Validation function, that returns 'undefined' for no error,
 * or error message in another case.
 */
export const createPasswordConfirmationFieldValidator = (
  passwordConfirmationFieldName,
  passwordFieldName,
) => (t, values) => {
  let error;

  const password = values[passwordFieldName];
  const confirmPassword = values[passwordConfirmationFieldName];

  const confirmPasswordError = createPasswordFieldValidator(passwordConfirmationFieldName)(
    t,
    values,
  );
  if (confirmPasswordError) {
    return confirmPasswordError;
  }

  if (password !== confirmPassword) {
    error = t("Validation.Password.Mismatch");
  }

  return error ? { [passwordConfirmationFieldName]: error } : undefined;
};

/**
 * Creates validation function for the verification code input field.
 *
 * @param fieldName - Field name.
 *
 * @returns Validation function, that returns 'undefined' for no error,
 * or error message in another case.
 */
export const createVerificationCodeFieldValidator = (fieldName) => (t, values) => {
  let error;

  const verificationCode = values[fieldName];

  if (verificationCode === undefined || verificationCode === "") {
    error = t("Validation.Required");
  } else if (VERIFICATION_CODE_REGEXP.test(verificationCode) === false) {
    error = t("Validation.VerificationCode.Format");
  } else if (verificationCode.length !== settings.Phone.VerificationCode.Length) {
    error = t("Validation.VerificationCode.Length", {
      length: settings.Phone.VerificationCode.Length,
    });
  }

  return error ? { [fieldName]: error } : undefined;
};

/**
 * Creates validation function for the terms of service input field.
 *
 * @param fieldName - Field name.
 *
 * @returns Validation function, that returns 'undefined' for no error,
 * or error message in another case.
 */
export const createTermsOfServiceFieldValidator = (fieldName) => (t, values) => {
  let error;

  const status = values[fieldName];

  if (status === false) {
    error = t("Validation.TermsOfService.Agreement");
  }

  return error ? { [fieldName]: error } : undefined;
};

/**
 * Creates validation function for the survey form input field.
 *
 * @param fieldName - Field name.
 *
 * @returns Validation function, that returns 'undefined' for no error,
 * or error message in another case.
 */
export const createSurveyFormFieldValidator = (fieldName) => (t, values) => {
  const commentFieldName = textToCommentFieldName(fieldName);
  const otherOptionFieldName = textToOtherOptionFieldName(fieldName);

  const value = values[fieldName];
  const commentValue = values[commentFieldName];

  if (value === undefined || value === "") {
    return { [fieldName]: t("Validation.Required") };
  }

  if (value === otherOptionFieldName) {
    if (commentValue === undefined || commentValue === "") {
      return {
        [commentFieldName]: t("Validation.Required"),
      };
    }
  }

  return undefined;
};

/**
 * Create validator function for the array of desired fields.
 *
 * @param t - Translation function.
 * @param validators - List of the validation functions to be used upon form validation.
 */
export const createFormValidator = (t, validators = []) => (values) => {
  let errors = {};

  validators.forEach((validator) => {
    const error = validator(t, values);

    if (error !== undefined) {
      errors = {
        ...errors,
        ...error,
      };
    }
  });

  return errors;
};
