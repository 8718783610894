import { encrypt } from "@aft/client-services/crypto";
import { FormFieldNames } from "@aft/client-services/forms";

import {
  SET_SIGN_IN_REASON,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  SESSION_CONTINUE_REQUEST,
  SESSION_CONTINUE_SUCCESS,
  SESSION_CONTINUE_FAILURE,
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
  SIGN_OUT_REQUEST,
  SIGN_OUT_SUCCESS,
  SIGN_OUT_FAILURE,
  PHONE_VERIFICATION_REQUEST,
  PHONE_VERIFICATION_SUCCESS,
  PHONE_VERIFICATION_FAILURE,
  CONFIRM_EMAIL_REQUEST,
  CONFIRM_EMAIL_SUCCESS,
  CONFIRM_EMAIL_FAILURE,
  RESET_PASSWORD_REQUEST_REQUEST,
  RESET_PASSWORD_REQUEST_SUCCESS,
  RESET_PASSWORD_REQUEST_FAILURE,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILURE,
} from "./actionTypes";

/**
 * Reason, why the user should perform a sign in.
 *
 * @param signInReason - Sign in reason.
 */
export const setSignInReason = (signInReason) => ({
  type: SET_SIGN_IN_REASON,
  signInReason,
});

/**
 * Sign in request action.
 *
 * @param credentials - User credentials to make sign in request with.
 */
export const signInRequest = (credentials) => ({
  type: SIGN_IN_REQUEST,
  credentials: {
    ...credentials,
    [FormFieldNames.Password]: encrypt(credentials[FormFieldNames.Password]),
  },
});

/**
 * Callback action for successful sign in attempt.
 */
export const signInSuccess = () => ({
  type: SIGN_IN_SUCCESS,
});

/**
 * Callback action for failed sign in attempt.
 *
 * @param error - Error message.
 */
export const signInFailure = (error) => ({
  type: SIGN_IN_FAILURE,
  error,
});

/**
 * Continue session request action.
 */
export const sessionContinueRequest = () => ({
  type: SESSION_CONTINUE_REQUEST,
});

/**
 * Callback action for successful continue session attempt.
 */
export const sessionContinueSuccess = () => ({
  type: SESSION_CONTINUE_SUCCESS,
});

/**
 * Callback action for failed continue session attempt.
 *
 * @param error - Error message.
 * @param disableNotification - Indicates whether to disable notification or not.
 */
export const sessionContinueFailure = (error, disableNotification = false) => ({
  type: SESSION_CONTINUE_FAILURE,
  error,
  disableNotification,
});

/**
 * Sign up request action.
 *
 * @param signUpData - User sign up data.
 */
export const signUpRequest = (signUpData) => ({
  type: SIGN_UP_REQUEST,
  signUpData: {
    ...signUpData,
    [FormFieldNames.Password]: encrypt(signUpData[FormFieldNames.Password]),
  },
});

/**
 * Callback action for successful sign up attempt.
 *
 * @param signUpData - User sign up data.
 */
export const signUpSuccess = (signUpData) => ({
  type: SIGN_UP_SUCCESS,
  signUpData,
});

/**
 * Callback action for failed sign up attempt.
 *
 * @param error - Error message.
 */
export const signUpFailure = (error) => ({
  type: SIGN_UP_FAILURE,
  error,
});

/**
 * Request action for destroying current user session (sign out).
 * Callback to this function resets all resettable reducer parts.
 */
export const signOutRequest = () => ({
  type: SIGN_OUT_REQUEST,
});

/**
 * Callback action for successful sign out attempt.
 */
export const signOutSuccess = () => ({
  type: SIGN_OUT_SUCCESS,
});

/**
 * Callback action for failed sign out attempt.
 *
 * @param error - Error message.
 */
export const signOutFailure = (error) => ({
  type: SIGN_OUT_FAILURE,
  error,
});

/**
 * Phone verification request action.
 *
 * @param verificationCode - Verification code.
 * @param callbackUrl - URL for callback token usage.
 * @param isRetry - Indicates whether current attempt is retry or not.
 */
export const phoneVerificationRequest = (verificationCode, callbackUrl, isRetry = false) => ({
  type: PHONE_VERIFICATION_REQUEST,
  verificationCode,
  callbackUrl,
  isRetry,
});

/**
 * Callback action for successful phone verification attempt.
 */
export const phoneVerificationSuccess = () => ({
  type: PHONE_VERIFICATION_SUCCESS,
});

/**
 * Callback action for failed phone verification attempt.
 *
 * @param error - Error message.
 */
export const phoneVerificationFailure = (error) => ({
  type: PHONE_VERIFICATION_FAILURE,
  error,
});

/**
 * Email confirmation request action.
 *
 * @param emailConfirmationToken - Email confirmation token.
 */
export const confirmEmailRequest = (emailConfirmationToken) => ({
  type: CONFIRM_EMAIL_REQUEST,
  emailConfirmationToken,
});

/**
 * Callback action for successful email confirmation attempt.
 */
export const confirmEmailSuccess = () => ({
  type: CONFIRM_EMAIL_SUCCESS,
});

/**
 * Callback action for failed email confirmation attempt.
 *
 * @param error - Error message.
 */
export const confirmEmailFailure = (error) => ({
  type: CONFIRM_EMAIL_FAILURE,
  error,
});

/**
 * Reset password request action.
 *
 * @param email - Email address to initiate password reset for.
 * @param callbackUrl - URL for callback token usage.
 * @param isRetry - Indicates whether current attempt is retry or not.
 */
export const resetPasswordRequestRequest = (email, callbackUrl, isRetry = false) => ({
  type: RESET_PASSWORD_REQUEST_REQUEST,
  email,
  callbackUrl,
  isRetry,
});

/**
 * Callback action for successful reset password request attempt.
 */
export const resetPasswordRequestSuccess = () => ({
  type: RESET_PASSWORD_REQUEST_SUCCESS,
});

/**
 * Callback action for failed reset password request attempt.
 *
 * @param error - Error message.
 */
export const resetPasswordRequestFailure = (error) => ({
  type: RESET_PASSWORD_REQUEST_FAILURE,
  error,
});

/**
 * Password update request action.
 *
 * @param token - Token with user data for password update.
 * @param password - New password to use.
 */
export const updatePasswordRequest = (token, password) => ({
  type: UPDATE_PASSWORD_REQUEST,
  token,
  password: encrypt(password),
});

/**
 * Callback action for successful password update request attempt.
 */
export const updatePasswordSuccess = () => ({
  type: UPDATE_PASSWORD_SUCCESS,
});

/**
 * Callback action for failed password update request attempt.
 *
 * @param error - Error message.
 */
export const updatePasswordFailure = (error) => ({
  type: UPDATE_PASSWORD_FAILURE,
  error,
});
