import {
  SET_CURRENT_USER_DATA,
  GET_CURRENT_USER_SUCCESS,
  UPDATE_CURRENT_USER_INFO_SUCCESS,
  UPDATE_CURRENT_USER_PHONE_NUMBER_SUCCESS,
  CONFIRM_CURRENT_USER_EMAIL_UPDATE_SUCCESS,
  UPDATE_CURRENT_USER_PASSWORD_SUCCESS,
} from "./actionTypes";

export const accountReducerInitialState = {
  user: {},
};

/**
 * Reducer, responsible for handling actions and store state relative to account.
 *
 * @param state - Current state of the reducer.
 * @param action - Action to be applied.
 */
export const accountReducer = (state = accountReducerInitialState, action) => {
  switch (action.type) {
    case SET_CURRENT_USER_DATA:
    case GET_CURRENT_USER_SUCCESS:
    case UPDATE_CURRENT_USER_INFO_SUCCESS:
    case UPDATE_CURRENT_USER_PHONE_NUMBER_SUCCESS:
    case CONFIRM_CURRENT_USER_EMAIL_UPDATE_SUCCESS:
    case UPDATE_CURRENT_USER_PASSWORD_SUCCESS:
      return {
        ...state,

        user: action.user || {},
      };
    default:
      return state;
  }
};
