import { call, put, select, takeLatest } from "redux-saga/effects";

import { applicationSagas } from "@aft/client-modules/application";
import { serverApiClient } from "@aft/server/apiClient";

import { PHONE_VALIDATION_REQUEST, REQUEST_PHONE_VERIFICATION_REQUEST } from "./actionTypes";
import {
  phoneValidationSuccess,
  phoneValidationFailure,
  requestPhoneVerificationSuccess,
  requestPhoneVerificationFailure,
} from "./actions";
import { selectPhoneNumber } from "./selectors";

function* phoneValidation() {
  try {
    const phoneNumber = yield select(selectPhoneNumber);

    yield call(serverApiClient.phone.validate, phoneNumber);

    yield put(phoneValidationSuccess());
  } catch (err) {
    yield put(phoneValidationFailure(err.message));
    yield call(applicationSagas.handleApiError, err);
  }
}

function* requestPhoneVerification() {
  try {
    const phoneNumber = yield select(selectPhoneNumber);

    const phoneVerificationToken = yield call(
      serverApiClient.phone.requestVerification,
      phoneNumber,
    );

    yield put(requestPhoneVerificationSuccess(phoneVerificationToken));
  } catch (err) {
    yield put(requestPhoneVerificationFailure(err.message));
    yield call(applicationSagas.handleApiError, err);
  }
}

/**
 * Root saga of the phone module.
 */
export function* phoneSaga() {
  yield takeLatest(PHONE_VALIDATION_REQUEST, phoneValidation);
  yield takeLatest(REQUEST_PHONE_VERIFICATION_REQUEST, requestPhoneVerification);
}
