/**
 * Selects sign-in status from the store.
 *
 * @param store - Combined store.
 * @param store.authorization - Authorization store.
 */
export const selectIsSignedIn = ({ authorization }) => authorization.isSignedIn;

/**
 * Selects sign up data from the store.
 *
 * @param store - Combined store.
 * @param store.authorization - Authorization store.
 */
export const selectSignUpData = ({ authorization }) => authorization.signUpData;
