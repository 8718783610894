const PREFIX = "PHONE/";

export const SET_PHONE_NUMBER = `${PREFIX}SET_PHONE_NUMBER`;

export const PHONE_VALIDATION_REQUEST = `${PREFIX}PHONE_VALIDATION_REQUEST`;
export const PHONE_VALIDATION_SUCCESS = `${PREFIX}PHONE_VALIDATION_SUCCESS`;
export const PHONE_VALIDATION_FAILURE = `${PREFIX}PHONE_VALIDATION_FAILURE`;

export const REQUEST_PHONE_VERIFICATION_REQUEST = `${PREFIX}REQUEST_PHONE_VERIFICATION_REQUEST`;
export const REQUEST_PHONE_VERIFICATION_SUCCESS = `${PREFIX}REQUEST_PHONE_VERIFICATION_SUCCESS`;
export const REQUEST_PHONE_VERIFICATION_FAILURE = `${PREFIX}REQUEST_PHONE_VERIFICATION_FAILURE`;
