const PREFIX = `ACCOUNT/`;

export const SET_CURRENT_USER_DATA = `${PREFIX}SET_CURRENT_USER_DATA`;

export const GET_CURRENT_USER_REQUEST = `${PREFIX}GET_CURRENT_USER_REQUEST`;
export const GET_CURRENT_USER_SUCCESS = `${PREFIX}GET_CURRENT_USER_SUCCESS`;
export const GET_CURRENT_USER_FAILURE = `${PREFIX}GET_CURRENT_USER_FAILURE`;

export const UPDATE_CURRENT_USER_INFO_REQUEST = `${PREFIX}UPDATE_CURRENT_USER_INFO_REQUEST`;
export const UPDATE_CURRENT_USER_INFO_SUCCESS = `${PREFIX}UPDATE_CURRENT_USER_INFO_SUCCESS`;
export const UPDATE_CURRENT_USER_INFO_FAILURE = `${PREFIX}UPDATE_CURRENT_USER_INFO_FAILURE`;

export const UPDATE_CURRENT_USER_PHONE_NUMBER_REQUEST = `${PREFIX}UPDATE_CURRENT_USER_PHONE_NUMBER_REQUEST`;
export const UPDATE_CURRENT_USER_PHONE_NUMBER_SUCCESS = `${PREFIX}UPDATE_CURRENT_USER_PHONE_NUMBER_SUCCESS`;
export const UPDATE_CURRENT_USER_PHONE_NUMBER_FAILURE = `${PREFIX}UPDATE_CURRENT_USER_PHONE_NUMBER_FAILURE`;

export const UPDATE_CURRENT_USER_EMAIL_REQUEST = `${PREFIX}UPDATE_CURRENT_USER_EMAIL_REQUEST`;
export const UPDATE_CURRENT_USER_EMAIL_SUCCESS = `${PREFIX}UPDATE_CURRENT_USER_EMAIL_SUCCESS`;
export const UPDATE_CURRENT_USER_EMAIL_FAILURE = `${PREFIX}UPDATE_CURRENT_USER_EMAIL_FAILURE`;

export const CONFIRM_CURRENT_USER_EMAIL_UPDATE_REQUEST = `${PREFIX}CONFIRM_CURRENT_USER_EMAIL_UPDATE_REQUEST`;
export const CONFIRM_CURRENT_USER_EMAIL_UPDATE_SUCCESS = `${PREFIX}CONFIRM_CURRENT_USER_EMAIL_UPDATE_SUCCESS`;
export const CONFIRM_CURRENT_USER_EMAIL_UPDATE_FAILURE = `${PREFIX}CONFIRM_CURRENT_USER_EMAIL_UPDATE_FAILURE`;

export const UPDATE_CURRENT_USER_PASSWORD_REQUEST = `${PREFIX}UPDATE_CURRENT_USER_PASSWORD_REQUEST`;
export const UPDATE_CURRENT_USER_PASSWORD_SUCCESS = `${PREFIX}UPDATE_CURRENT_USER_PASSWORD_SUCCESS`;
export const UPDATE_CURRENT_USER_PASSWORD_FAILURE = `${PREFIX}UPDATE_CURRENT_USER_PASSWORD_FAILURE`;
