import axios from "axios";

import { settings } from "@aft/client-settings";
import { RequestMethods } from "@aft/constants/general/requests";
import { AftServices } from "@aft/constants/general/services";
import { Routes } from "@aft/server/src/config/routes";
import { makeFullUrl } from "@aft/utils/makeFullUrl";

/**
 * API client base class, that provides connection in-between all AFT server and client services.
 */
export class ServerApiClient {
  /**
   * Creates new {@link ServerApiClient} instance.
   */
  constructor() {
    // Axios instance for interaction with the main server.
    this.serverAxiosInstance = axios.create({
      baseURL: settings.Services[AftServices.Server].Url,
      withCredentials: true,
    });

    this.serverAxiosInstance.interceptors.response.use(
      ({ data }) => data,
      (error) => Promise.reject(error.response?.data ? error.response.data : error),
    );

    this.serverApiRequest = (method, url, data = {}, options = {}) =>
      method === RequestMethods.Get
        ? this.serverAxiosInstance[method.toLowerCase()](
            makeFullUrl(url, JSON.parse(JSON.stringify(data))),
            JSON.parse(JSON.stringify(options)),
          )
        : this.serverAxiosInstance[method.toLowerCase()](
            url,
            data,
            JSON.parse(JSON.stringify(options)),
          );
  }

  /**
   * Authorization API.
   */
  authorization = {
    /**
     * Sign in request.
     *
     * @param credentials - User credentials.
     * @param options - Request options.
     */
    signIn: (credentials = {}, options = {}) =>
      this.serverApiRequest(
        RequestMethods.Post,
        Routes.Authorization.SignIn.path,
        credentials,
        options,
      ),

    /**
     * Request for session continuation.
     *
     * @param options - Request options.
     */
    sessionContinue: (options = {}) =>
      this.serverApiRequest(
        RequestMethods.Post,
        Routes.Authorization.Session.Continue.path,
        undefined,
        options,
      ),

    /**
     * Sign out current user.
     */
    signOut: () => this.serverApiRequest(RequestMethods.Post, Routes.Authorization.SignOut.path),

    /**
     * Phone verification request.
     *
     * @param phoneVerificationToken - Phone verification token.
     * @param verificationCode - Phone verification code.
     * @param user - User data for callback action.
     * @param callbackUrl - URL for callback token usage.
     * @param options - Request options.
     */
    phoneVerification: (
      phoneVerificationToken,
      verificationCode,
      user,
      callbackUrl,
      options = {},
    ) =>
      this.serverApiRequest(
        RequestMethods.Post,
        Routes.Authorization.Phone.Verification.path,
        {
          phoneVerificationToken,
          verificationCode,
          user,
          callbackUrl,
        },
        options,
      ),

    /**
     * Email confirmation request.
     *
     * @param emailConfirmationToken - Email confirmation token.
     * @param options - Request options.
     */
    confirmEmail: (emailConfirmationToken, options = {}) =>
      this.serverApiRequest(
        RequestMethods.Post,
        Routes.Authorization.Email.Confirm.path,
        {
          emailConfirmationToken,
        },
        options,
      ),

    /**
     * Update password request.
     *
     * @param token - Confirmation token.
     * @param password - Password to set.
     * @param options - Request options.
     */
    updatePassword: (token, password, options = {}) =>
      this.serverApiRequest(
        RequestMethods.Put,
        Routes.Authorization.Password.path,
        {
          token,
          password,
        },
        options,
      ),

    /**
     * Reset password request.
     *
     * @param email - User email.
     * @param callbackUrl - URL for callback token usage.
     * @param options - Request options.
     */
    resetPasswordRequest: (email, callbackUrl, options = {}) =>
      this.serverApiRequest(
        RequestMethods.Post,
        Routes.Authorization.Password.Reset.path,
        {
          email,
          callbackUrl,
        },
        options,
      ),
  };

  /**
   * Holdings API.
   */
  holdings = {
    /**
     * Request list of holdings.
     *
     * @param query - Search query.
     * @param options - Request options.
     */
    getHoldingsList: (query, options = {}) =>
      this.serverApiRequest(RequestMethods.Get, Routes.Holdings.path, query, options),

    /**
     * Get range of dates, where holdings data exists.
     *
     * @param options - Request options.
     */
    getPredictionsDatesRange: (options = {}) =>
      this.serverApiRequest(
        RequestMethods.Get,
        Routes.Holdings.DatesRange.path,
        undefined,
        options,
      ),
  };

  /**
   * Phone API.
   */
  phone = {
    /**
     * Validate phone number.
     *
     * @param phoneNumber - Phone number.
     * @param options - Request options.
     */
    validate: (phoneNumber, options = {}) =>
      this.serverApiRequest(
        RequestMethods.Post,
        Routes.Phone.Validate.path,
        {
          phoneNumber,
        },
        options,
      ),

    /**
     * Request phone number verification.
     *
     * @param phoneNumber - Phone number.
     * @param options - Request options.
     */
    requestVerification: (phoneNumber, options = {}) =>
      this.serverApiRequest(
        RequestMethods.Post,
        Routes.Phone.RequestVerification.path,
        {
          phoneNumber,
        },
        options,
      ),
  };

  /**
   * Subscription API.
   */
  subscription = {
    /**
     * Get list of subscription prices.
     *
     * @param options - Request options.
     */
    getPrices: (options = {}) =>
      this.serverApiRequest(
        RequestMethods.Get,
        Routes.Subscription.Prices.path,
        undefined,
        options,
      ),
  };

  /**
   * Support API.
   */
  support = {
    /**
     * Post technical support request.
     *
     * @param data - Request data.
     * @param options - Request options.
     */
    postTechnicalSupportRequest: (data, options = {}) =>
      this.serverApiRequest(RequestMethods.Post, Routes.Support.Technical.path, data, options),
  };

  /**
   * Survey API.
   */
  survey = {
    /**
     * Post survey results.
     *
     * @param surveyData - Survey data.
     * @param options - Request options,.
     */
    postSurveyResults: (surveyData, options = {}) =>
      this.serverApiRequest(RequestMethods.Post, Routes.Survey.path, { surveyData }, options),
  };

  /**
   * Users API.
   */
  users = {
    /**
     * Validate user data.
     *
     * @param user - User data.
     * @param isNew - Indicates whether user is new.
     * @param options - Request options.
     */
    validate: (user, isNew = true, options = {}) =>
      this.serverApiRequest(
        RequestMethods.Post,
        Routes.Users.Validate.path,
        { user, isNew },
        options,
      ),

    /**
     * Request current user info.
     *
     * @param options - Request options.
     */
    getCurrentUser: (options = {}) =>
      this.serverApiRequest(RequestMethods.Get, Routes.Users.Current.path, undefined, options),

    /**
     * Update current user info.
     *
     * @param data - User data to update.
     * @param options - Request options.
     */
    updateCurrentUserInfo: (data, options = {}) =>
      this.serverApiRequest(RequestMethods.Put, Routes.Users.Current.Info.path, data, options),

    /**
     * Update current user phone number.
     *
     * @param data - Data for phone number update.
     * @param options - Request options.
     */
    updateCurrentUserPhoneNumber: (data, options = {}) =>
      this.serverApiRequest(RequestMethods.Put, Routes.Users.Current.Phone.path, data, options),

    /**
     * Update current user email.
     *
     * @param email - Email to set.
     * @param callbackUrl - URL for callback token usage.
     * @param options - Request options.
     */
    updateCurrentUserEmail: (email, callbackUrl, options = {}) =>
      this.serverApiRequest(
        RequestMethods.Put,
        Routes.Users.Current.Email.path,
        {
          email,
          callbackUrl,
        },
        options,
      ),

    /**
     * Current user email update confirmation.
     *
     * @param token - Confirmation token.
     * @param options - Request options.
     */
    confirmCurrentUserEmailUpdate: (token, options = {}) =>
      this.serverApiRequest(
        RequestMethods.Post,
        Routes.Users.Current.Email.Confirm.path,
        {
          token,
        },
        options,
      ),

    /**
     * Update current user password.
     *
     * @param data - Update password data.
     * @param options - Request options.
     */
    updateCurrentUserPassword: (data, options = {}) =>
      this.serverApiRequest(RequestMethods.Put, Routes.Users.Current.Password.path, data, options),

    /**
     * Create current user subscription.
     *
     * @param stripeSubscriptionPriceId - Stripe subscription price ID.
     * @param stripePaymentMethodId - Stripe payment method ID.
     * @param options - Request options.
     */
    createCurrentUserSubscription: (
      stripeSubscriptionPriceId,
      stripePaymentMethodId,
      options = {},
    ) =>
      this.serverApiRequest(
        RequestMethods.Post,
        Routes.Users.Current.Subscription.path,
        {
          stripeSubscriptionPriceId,
          stripePaymentMethodId,
        },
        options,
      ),

    /**
     * Update current user subscription.
     *
     * @param stripeSubscriptionPriceId - Stripe subscription price ID.
     * @param options - Request options.
     */
    updateCurrentUserSubscription: (stripeSubscriptionPriceId, options = {}) =>
      this.serverApiRequest(
        RequestMethods.Put,
        Routes.Users.Current.Subscription.path,
        {
          stripeSubscriptionPriceId,
        },
        options,
      ),

    /**
     * Cancel current user subscription.
     *
     * @param surveyData - Survey data.
     * @param options - Request options.
     */
    cancelCurrentUserSubscription: (surveyData, options = {}) =>
      this.serverApiRequest(
        RequestMethods.Post,
        Routes.Users.Current.Subscription.Cancel.path,
        {
          surveyData,
        },
        options,
      ),

    /**
     * Get upcoming payment in the current user subscription.
     *
     * @param options - Request options.
     */
    getCurrentUserSubscriptionPaymentUpcoming: (options = {}) =>
      this.serverApiRequest(
        RequestMethods.Get,
        Routes.Users.Current.Subscription.Payments.Upcoming.path,
        undefined,
        options,
      ),

    /**
     * Get current user payment method.
     *
     * @param options - Request options.
     */
    getCurrentUserPaymentMethod: (options = {}) =>
      this.serverApiRequest(
        RequestMethods.Get,
        Routes.Users.Current.PaymentMethod.path,
        undefined,
        options,
      ),

    /**
     * Update current user payment method.
     *
     * @param stripePaymentMethodId - Payment method ID.
     * @param options - Request options.
     */
    updateCurrentUserPaymentMethod: (stripePaymentMethodId, options = {}) =>
      this.serverApiRequest(
        RequestMethods.Put,
        Routes.Users.Current.PaymentMethod.path,
        {
          stripePaymentMethodId,
        },
        options,
      ),
  };
}
