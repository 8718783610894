import { omit } from "ramda";

import { applicationActionTypes } from "@aft/client-modules/application";
import {
  AsyncActionStages,
  actionTypeToMethodName,
  getAsyncActionStage,
} from "@aft/client-modules/helpers";
import { settings } from "@aft/client-settings";

import { OPEN_NOTIFICATION, CLOSE_NOTIFICATION, CLOSE_ALL_NOTIFICATIONS } from "./actionTypes";
import { NotificationStatuses } from "./constants";

export const notificationsReducerInitialState = [];

/**
 * Reducer, responsible for handling actions and store state relative to notifications functionality.
 *
 * @param state - Current state of the reducer.
 * @param action - Action to be applied.
 */
export const notificationsReducer = (state = notificationsReducerInitialState, action) => {
  switch (action.type) {
    case applicationActionTypes.ROUTE_CHANGE_COMPLETE:
      return state.filter(({ options: { dismissOnRouteChange } }) => !dismissOnRouteChange);
    case OPEN_NOTIFICATION:
      return [...state, omit(["type"], action)].slice(-settings.Notifications.MaximalNumber);
    case CLOSE_NOTIFICATION:
      return state.filter(({ id }) => id !== action.id);
    case CLOSE_ALL_NOTIFICATIONS:
      return [...notificationsReducerInitialState];
    default:
      break;
  }

  // Validate action types, coming from other modules.
  // If this is async action, and notification is not omitted, add it to a store.
  const asyncActionStage = getAsyncActionStage(action.type);
  if (asyncActionStage !== undefined) {
    const methodName = actionTypeToMethodName(action.type);

    switch (asyncActionStage) {
      case AsyncActionStages.Request:
        return state.filter(({ id }) => id !== methodName);
      case AsyncActionStages.Failure:
        return action.disableNotification
          ? state
          : [
              ...state,
              {
                status: NotificationStatuses.Error,
                message: action.error,
                id: methodName,
                options: {
                  dismissOnRouteChange: true,
                },
              },
            ].slice(-settings.Notifications.MaximalNumber);
      default:
        return state;
    }
  }

  return state;
};
