import { settings } from "@aft/client-settings";

import { OPEN_NOTIFICATION, CLOSE_NOTIFICATION, CLOSE_ALL_NOTIFICATIONS } from "./actionTypes";
import { NotificationStatuses } from "./constants";

/**
 * Open notification.
 *
 * @param status - Status.
 * @param message - Message.
 * @param options - Options.
 */
export const openNotification = (
  status = NotificationStatuses.Success,
  message = "",
  options = {},
) => ({
  type: OPEN_NOTIFICATION,
  status,
  message,
  id: String(new Date().getTime()),
  options: {
    autoDismiss: settings.Notifications.Default.AutoDismiss,
    ...options,
  },
});

/**
 * Open success notification.
 *
 * @param message - Message.
 * @param options - Options.
 */
export const openSuccessNotification = (message, options) =>
  openNotification(NotificationStatuses.Success, message, options);

/**
 * Open info notification.
 *
 * @param message - Message.
 * @param options - Options.
 */
export const openInfoNotification = (message, options) =>
  openNotification(NotificationStatuses.Info, message, options);

/**
 * Open error notification.
 *
 * @param message - Message.
 * @param options - Options.
 */
export const openErrorNotification = (message, options) =>
  openNotification(NotificationStatuses.Error, message, options);

/**
 * Open warning notification.
 *
 * @param message - Message.
 * @param options - Options.
 */
export const openWarningNotification = (message, options) =>
  openNotification(NotificationStatuses.Warning, message, options);

/**
 * Close notification.
 *
 * @param id - Notification ID.
 */
export const closeNotification = (id) => ({
  type: CLOSE_NOTIFICATION,
  id,
});

/**
 * Close all notifications.
 */
export const closeAllNotifications = () => ({
  type: CLOSE_ALL_NOTIFICATIONS,
});
