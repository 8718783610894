/**
 * Make full URL by appending query parameters to a base URL and encode the result.
 *
 * @param url - Base URL.
 * @param query - Query parameters to add.
 */
export const makeFullUrl = (url, query) =>
  encodeURI(
    Object.keys(query).length > 0
      ? `${url}?${Object.keys(query)
          .map((key) => `${key}=${query[key]}`)
          .join("&")}`
      : url,
  );
